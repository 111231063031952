@import "../../node_modules/@clr/ui/clr-ui-dark.min.css";
.in-overflow .tabs-overflow > .nav-item > button {
  box-shadow: 0 -3px 0 #4aaed9 inset;
  color: #0077b8;
}

.label-form {
  background-color: #212129 !important;
}

.esxc-gauge-circle-bgc-dark {
  background-color: #1b2a32 !important;
}

.harbor-icon {
  transform: translateX(0) !important;
  filter: drop-shadow(#ccc 58px 2px) !important;
}

.color-white-dark {
  color: white;
}

.label-filter-panel {
  background-color: #1b2a32 !important;
}

.filter-dark {
  color: #acbac3 !important;
}

.side-form {
  background-color: #1b2a32 !important;
}

.toolBar {
  background-color: #1b2a32 !important;
}

.search-overlay {
  background-color: #1b2a32 !important;
}

.add-modal-dark {
  background: #17242b !important;
}

.license {
  background-color: #1b2a32 !important;
}

.pagination-list .pagination-current {
  color: #acbac3 !important;
}

.no-info-div,
.info-div {
  background-color: #1b2a32 !important;
}

.bottom-line-project-config {
  color: #acbac3 !important;
}

.config-subtext {
  color: #acbac3 !important;
}

.permission-dark {
  color: #acbac3 !important;
}

.scanner-name {
  color: #acbac3 !important;
}

.summary-dark {
  color: #acbac3 !important;
}
.summary-dark h5 {
  color: #acbac3 !important;
}

.about-version {
  color: #eaedf0 !important;
}

.update-time {
  color: #acbac3 !important;
}

.font-style {
  color: #acbac3 !important;
}

.go-link {
  color: #4aaed9 !important;
  border-bottom: 1px solid #4aaed9 !important;
}

.circle-block {
  color: #acbac3 !important;
}

.executions-detail label {
  color: #acbac3 !important;
}

.black-point {
  background-color: #acbac3 !important;
}

clr-dg-action-overflow clr-icon {
  fill: #acbac3 !important;
}

.oidc-tip {
  color: #acbac3 !important;
}

.clr-select-wrapper select option {
  background: #acbac3;
  color: #1b2a32;
}

hbr-tag .color-green {
  color: #4cd400 !important;
}

.filter-divider {
  background-color: #495865;
}

.selectBox {
  background: #acbac3 !important;
  color: #1b2a32 !important;
}

.selectBox ul li:hover {
  background-image: linear-gradient(180deg, #4aaed9 0, #0077b8) !important;
}

.btn-tag-integration .active {
  background: #4aaed9;
  color: #000;
}

hbr-result-tip-histogram .inner {
  background-color: #21333b;
}

.md-div pre:not([class*=language-]) code:not([class*=language-]), .md-div code:not([class*=language-]) {
  background: #21333b;
  border-radius: 2px;
  padding: 2px 4px;
}

:not(pre) > code[class*=language-], pre[class*=language-] {
  background-color: #253c46;
}

.md-div code:not([class*=language-]) {
  color: none;
}
.md-div pre:not([class*=language-]) {
  background: none;
}
.md-div pre:not([class*=language-]) code:not([class*=language-]) {
  background: transparent;
}
.md-div table {
  display: block;
  width: 100%;
  overflow: auto;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 16px;
}
.md-div table td,
.md-div table th {
  padding: 6px 13px;
  border: 1px solid #21333b;
}
.md-div table td[align="$var"],
.md-div table th[align="$var"] {
  text-align: left;
}
.md-div table td[align="$var"],
.md-div table th[align="$var"] {
  text-align: right;
}
.md-div table td[align="$var"],
.md-div table th[align="$var"] {
  text-align: center;
}
.md-div table tr:nth-child(2n) {
  background-color: none;
}

.table-tag .tag-thead .tag-header-color {
  color: #17242b;
}
.table-tag .tag-tbody .tag-tr .tag-body-color {
  color: #1b2a32;
}

clr-header {
  background-color: #00364d !important;
}